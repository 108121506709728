/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import * as React from "react";
import { Menu, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import {
  MenuItem,
  Divider,
  ListItemIcon,
  ListItemText,
  Button,
} from "@mui/material";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import EmojiPeopleIcon from "@mui/icons-material/EmojiPeople";
import BookmarkAddedIcon from "@mui/icons-material/BookmarkAdded";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import TimelineIcon from "@mui/icons-material/Timeline";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import LogoutIcon from "@mui/icons-material/Logout";
import AssignmentIndOutlinedIcon from "@mui/icons-material/AssignmentIndOutlined";
import InfoIcon from "@mui/icons-material/Info";
import SubjectIcon from "@mui/icons-material/Subject";
import AccountTreeIcon from "@mui/icons-material/AccountTree";
import PeopleIcon from "@mui/icons-material/People";
import PasswordIcon from "@mui/icons-material/Password";
import GroupsIcon from "@mui/icons-material/Groups";
import PsychologyIcon from "@mui/icons-material/Psychology";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "#fff",
  },
  menuIcon: {
    color: "#fff",
  },
}));

export default function MenuUser(props) {
  const classes = useStyles();
  const hasThem = global.hasRoles("Admin");
  const hasSystem = global.hasRoles("System");
  const hasPhanQuyen = global.hasRoles("UMS.R111");
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event) => {
    setAnchorEl(null);
  };
  return (
    <>
      <IconButton
        sx={{
          paddingTop: 0,
          paddingBottom: 0,
          padingLeft: 1,
        }}
        onClick={(event) => setAnchorEl(event.target)}
      >
        <AccountCircleIcon style={{ color: "white" }} />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        <MenuItem>
          <ListItemIcon>
            <AccountCircleIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>{props.UserName}</ListItemText>
        </MenuItem>
        {hasThem ? (
          <div>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                props.onClick("phanmem");
              }}
            >
              <ListItemIcon>
                <SubjectIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Danh sách phần mềm</ListItemText>
            </MenuItem>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                props.onClick("nhomquyen");
              }}
            >
              <ListItemIcon>
                <AccountTreeIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Danh sách nhóm quyền</ListItemText>
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        {hasPhanQuyen ? (
          <div>
            <MenuItem
              onClick={() => {
                handleClose();
                props.onClick("donvi");
              }}
            >
              <ListItemIcon>
                <GroupsIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Bộ phận</ListItemText>
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        {hasPhanQuyen ? (
          <div>
            <MenuItem
              onClick={() => {
                handleClose();
                props.onClick("phanquyen");
              }}
            >
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Phân quyền</ListItemText>
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        <MenuItem
          onClick={() => {
            handleClose();
            props.onClick("password");
          }}
        >
          <ListItemIcon>
            <PasswordIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Đổi mật khẩu</ListItemText>
        </MenuItem>
        {hasSystem ? (
          <>
            <Divider />
            <MenuItem
              onClick={() => {
                handleClose();
                props.onClick("system");
              }}
            >
              <ListItemIcon>
                <PsychologyIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>View log</ListItemText>
            </MenuItem>
          </>
        ) : null}
        <Divider />
        <MenuItem
          onClick={() => {
            handleClose();
            props.onClick("logout");
          }}
        >
          <ListItemIcon>
            <LogoutIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>Thoát ra</ListItemText>
        </MenuItem>
        <Divider />
        <MenuItem onClick={() => {}}>
          <ListItemIcon>
            <InfoIcon fontSize="small" />
          </ListItemIcon>
          <ListItemText>
            {"Version: " + window.myconfig.SPAVersion}
          </ListItemText>
        </MenuItem>
      </Menu>
    </>
  );
}
