/**Nhap danh co quan */
import {
  Button,
  Dialog,
  DialogContent,
  Grid
} from "@mui/material";
import React from "react";
import "react-toastify/dist/ReactToastify.css";

//////////////////////////////////////////////---------------///////////////////////////////////////////////////////
export default class ModuleSelectDialog extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() { }

  //call khi user thay doi trang
  static getDerivedStateFromProps(nextProps) {
    return { open: nextProps.open };
  }

  render() {
    return (
      <Dialog
        open={this.props.open}
        maxWidth={"sm"}
        fullWidth={true}
        onClose={() => { }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <div
            style={{
              minWidth: 500,
              minHeight: 30,
              padding: 5,
              fontSize: 10,
              wordWrap: "break-word",
            }}
          >
            <Grid container spacing={1} columns={24}>
              <Grid item xs={12}>
                <Button
                  color={"success"}
                  variant="contained"
                  fullWidth
                  onClick={() => {
                    this.props.close();
                  }}
                >
                  Noon Report
                </Button>
              </Grid>
              <Grid item xs={12}>
                <Button
                  color={"primary"}
                  variant="contained"
                  fullWidth
                  href="https://chiphi.vosco.vietms.vn/"                  
                >
                  Quản lý chi phí
                </Button>
              </Grid>
            </Grid>
          </div>
        </DialogContent>
      </Dialog>
    );
  }
}
