import React, { useState } from "react";
import {
  Box,
  TextField,
  Button,
  IconButton,
  Select,
  MenuItem,
  Stack,
  Grid,
  Paper,
  InputLabel,
  FormLabel,
  Tabs,
  Tab,
  useRadioGroup,
  RadioGroup,
  FormControlLabel,
  Radio,
  Checkbox,
  OutlinedInput,
  FormControl,
  Typography,
  Link,
  CircularProgress,
} from "@mui/material";
import Dropzone from "react-dropzone";
import moment from "moment";
import SelectFilter from "react-select";
import {
  filterStyles,
  formStyles,
  loadDataError,
  handleServerError,
  ReactDataGrid_i18n,
  ReactDataGrid_default,
  showError,
} from "../components/common";
import localConfig from "local-storage";
import { makeStyles, withStyles } from "@mui/styles";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import NumericFormat from "react-number-format";
import ReactDataGrid from "@inovua/reactdatagrid-enterprise";
import "@inovua/reactdatagrid-enterprise/index.css";
import AsyncSelect from "react-select/async";
/*----------------------Cac control trong project su dung den trong form----------------*/
import MyNumberEditor from "../components/MyNumberEditor";
import MyDateEditor from "../components/MyDateEditor";
import MyDateTimeEditor from "../components/MyDateTimeEditor";
import PagingSearchCodeDialog from "../tools/PagingSearchCodeDialog";
import SearchFormColumnsDialog from "../tools/SearchFormColumnsDialog";
import GenerateCodeDialog from "../tools/GenerateCodeDialog";
import ButtonTimKiem from "../components/ButtonTimKiem";
import DataEditDialog from "../templates/DataEditDialog";
import MenuButtons from "../components/MenuButtons";
import MySelectFilter from "../components/MySelectFilter";
import SimpleDialog from "../components/SimpleDialog";
import { TabContext, TabList, TabPanel } from "@mui/lab";
import { ThirtyFpsSelect } from "@mui/icons-material";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../lib/MyLib");
const server = require("../lib/server");
const dataStruct = require("../data/DataStruct");
/*-----------------------Cac dinh nghia ----------------------------------*/

/*-----------------------Dinh nghia cac column trong grid -----------------*/
const default_gridColumns = [
  {
    name: "_STT",
    type: "number",
    headerAlign: "center",
    defaultWidth: 120,
    header: "STT",
  },
];
/*-----------------------Cac bien su dung trong form ----------------------*/
const defaultSortInfo = { name: "_STT", dir: 0, type: "number" };
//tra ve index column tuy theo sort infor
const getSortColumnIndex = (columns, sortInfor) => {
  var find_index = 0;
  for (var i = 0; i < columns.length; i++) {
    var item = columns[i];
    if (item.name == sortInfor.name) {
      find_index = i;
      break;
    }
  }
  return find_index;
};
const tabContentStyles = (max, txt) => {
  return {
    padding: "2px",
    height:
      max - 64 - (txt && txt.current ? txt.current.clientHeight : 36) + "px",
  };
};
////////////////////////////////////////////////////////////////////////////
class SystemLogPage extends React.Component {
  selectionCounter = 0; //dem so lan click chon row trong grid
  lastClick = 0; //danh dau lan click truoc do' cua grid de xu ly double click
  constructor(props) {
    super(props);
    this.columns_grid = default_gridColumns;
    //load lai thong tin giao dien cua phien lam viec truoc do
    this.state = {
      refCommand: React.createRef(),
      dataChanged: false, //du lieu co su thay doi hay khong
      loadOnStart: true, //co load du lieu ngay khi mo form khong
      errorMessage: "", //mo ta loi neu co
      errorLog: "", //mo ta chi tiet loi
      //searching
      search_loading: false,
      search_result: { data: [], count: 0 }, //ket qua tim kiem
      search_dataSource: [],
      search_error: false, //search co loi khong
      lastSortInfo: defaultSortInfo, //vi grid co trang thai sortinfo = null nen phai co cai nay
      sortInfo: defaultSortInfo, //thong tin sap xep cua grid
      //chua cac tieu chi tim kiem
      after:
        props.value === undefined //gia tri truyen vao tu form cha
          ? {
              PageIndex: 1,
              PageSize: localStorage.getItem("SystemLogPage.PageSize") ?? 20,
              Ascending: true,
              SortBy: getSortColumnIndex(this.columns_grid, defaultSortInfo), //index column duoc sap xep, anh xa giua ten cot duoc chon va index cua colmn trong grid
              //danh sach cac tieu chi tim kiem
              Command: "",
              password: "",
            }
          : props.value, //thong tin tieu chi truyen vao tu form cha

      //ban ghi duoc lua chon trong grid
      rowSelected: undefined, //record trong danh sach chuyen duoc chon
      dataSet: [], //danh sach du lieu hien thi
      selectedTab: null,
      cellSelection: {},
    };
  }
  //ghi nhan 1 tieu chi tim kiem
  saveField = (fieldName, value) => {
    var s = this.state;
    if (fieldName !== "PageIndex") {
      s.after.PageIndex = 1;
    } else {
      if (s.after.PageIndex === value) {
        return; //ko co su thay doi thi bo qua
      }
    }
    s.after[fieldName] = value;
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (
      fieldName !== "PageSize" &&
      fieldName !== "PageIndex" &&
      fieldName !== "SortBy"
    ) {
      this.setMyState(this.state);
    } else {
      //lien quan den phan trang hoac sap xep thi reload
      this.setMyState(this.state, () => {
        this.doSearch();
      });
    }
  };
  //ghi nhan nhieu gia tri tieu chi cung 1 luc
  saveData = (obj) => {
    var s = this.state;
    s.after = { ...s.after, ...obj };
    s.dataChanged = true;
    s.canSaved = s.dataChanged;
    if (obj.Ascending || obj.SortBy || obj.VoyageCode) {
      this.setMyState(s, () => this.doSearch());
    } else {
      this.setMyState(s, () => this.doSearch());
    }
  };
  //duoc su dung neu form hien thi trong che do MDI
  setMyState = (state, callback) => {
    this.setState(state, () => {
      //su dung cho muc dich MDI form
      if (this.props.onStateChanged) {
        this.props.onStateChanged(this.state);
      }
      //reload data
      if (callback) {
        callback();
      }
    });
  };
  //su kien form load
  componentDidMount() {
    this.loadDanhMuc(() => {
      this.doSearch();
    });
  }
  static getDerivedStateFromProps(nextProps, prevState) {
    return null;
  }
  shouldComponentUpdate(nextProps, nextState) {
    return true;
  }

  componentDidUpdate(prevProps) {
    //neu thong tin tau thay doi
  }
  //load du lieu danh muc theo danh sach table
  loadDanhMuc(nextFunc) {}
  //khi thay doi sap xep cot
  onSortInfoChange = (value) => {
    const newSort = value
      ? { type: value.type, name: value.name, dir: value.dir }
      : value;
    //ghi nhan vao sort by
    this.setState(
      {
        lastSortInfo: this.state.sortInfo
          ? this.state.sortInfo
          : this.state.lastSortInfo,
        sortInfo: newSort,
      },
      () => this.doSearch()
    );
  };

  //Thuc hien search
  doSearch = () => {
    this.setState({
      search_loading: true,
    });
    try {
      //call
      server
        .post("Data/DoRequest", {
          Function: "Proc_SystemLog_Check",
          ThamSo: this.state.after,
        })
        .then((response) => {
          let keys = Object.keys(response);
          //thiet lap danh sach du lieu
          var list = [];
          keys.map((name, index) => {
            //tao danh sach columns cho tung grid
            var columns = [];
            var table = {};
            var data = response[name]; //array
            data.map((record, i) => {
              let fields = Object.keys(record); //danh sach ten truong
              fields.map((f) => {
                if (table[f] == undefined) {
                  //chua co truong thi cho vao
                  table[f] = f;
                  columns.push({
                    name: f,
                    headerAlign: "center",
                    defaultWidth: 120,
                    header: f,
                    render: (props) => props.data[f] + "",
                  });
                }
              });
              //danh sach
              record._STT = i;
            });
            table.columns = columns;
            table.records = data;
            table.name = "Table" + (index + 1);
            list.push(table);
          });
          console.log(list);
          this.setState({
            search_loading: false,
            dataSet: list,
            selectedTab: "Table1",
          });
        })
        .catch((error) => {
          handleServerError(error);
          this.setState({ search_loading: false });
        });
    } catch (e) {
      this.setState({
        search_loading: false,
      });

      showError(e);
    }
  };

  //call khi user thay doi trang
  render() {
    //lay mot so thuoc tinh truyen vao tu form cha
    const { classes, theme, parentHeight } = this.props;
    return (
      <div>
        <Paper variant="outlined">
          <Grid container spacing={2} columns={24}>
            <Grid item xs={21}>
              <OutlinedInput
                ref={this.state.refCommand}
                fullWidth
                minRows={3}
                value={this.state.after.Command}
                multiline
                maxRows={15}
                onChange={(event) =>
                  this.saveField("Command", event.target.value)
                }
              />
            </Grid>

            <Grid item xs={3}>
              <Grid container spacing={1}>
                <Grid item>
                  {this.state.search_loading ? (
                    <CircularProgress />
                  ) : (
                    <ButtonTimKiem
                      variant="contained"
                      fullWidth
                      title="Tìm kiếm"
                      disabled={this.state.search_loading}
                      onClick={this.doSearch}
                    ></ButtonTimKiem>
                  )}
                </Grid>
                <Grid item>
                  <OutlinedInput
                    inputMode="text"
                    type="password"
                    fullWidth
                    value={this.state.after.password}
                    onChange={(event) =>
                      this.saveField("password", event.target.value)
                    }
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
        <div style={{ height: 2 }}></div>
        <Grid container spacing={1} columns={24}>
          <Grid item xs={24}>
            <TabContext value={this.state.selectedTab}>
              {this.state.dataSet.map((table, index) => (
                <TabPanel
                  value={table.name}
                  sx={tabContentStyles(parentHeight, this.state.refCommand)}
                >
                  <ReactDataGrid
                    style={{
                      height: "100%",
                      fontSize: 10,
                    }}
                    columns={table.columns}
                    pagination={false}
                    dataSource={table.records}
                    rowHeight={28}
                    idProperty="_STT"
                    enableSelection={true}
                    defaultCellSelection={[]}
                  ></ReactDataGrid>
                </TabPanel>
              ))}
              <TabList
                onChange={(event, tabName) =>
                  this.setState({ selectedTab: tabName })
                }
                variant="fullWidth"
              >
                {this.state.dataSet.map((table, index) => (
                  <Tab label={table.name} value={table.name} />
                ))}
              </TabList>
            </TabContext>
          </Grid>
        </Grid>
        <ToastContainer
          position="top-right"
          autoClose={false}
          hideProgressBar
          newestOnTop={true}
          closeOnClick={false}
          rtl={false}
          pauseOnFocusLoss
          draggable={false}
          pauseOnHover={false}
          style={{ fontSize: 12, width: 750 }}
          limit={5}
        />
      </div>
    );
  }
}
export default withStyles(formStyles, { withTheme: true })(SystemLogPage);
