/* Khong hieu sao phai cho menu ra ngoai file rieng thi moi o duoi appbar */
import * as React from "react";
import { Menu, IconButton } from "@mui/material";
import { makeStyles } from "@mui/styles";
import { MenuItem, Divider, ListItemIcon, ListItemText } from "@mui/material";
import ClassIcon from "@mui/icons-material/Class";
import Typography from "@mui/material/Typography";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CollectionsBookmarkIcon from "@mui/icons-material/CollectionsBookmark";
import DirectionsBoatIcon from "@mui/icons-material/DirectionsBoat";
import LocalActivityIcon from "@mui/icons-material/LocalActivity";
import BusinessIcon from "@mui/icons-material/Business";
import FormatListNumberedIcon from "@mui/icons-material/FormatListNumbered";
import PagesIcon from "@mui/icons-material/Pages";
import ForwardToInboxIcon from '@mui/icons-material/ForwardToInbox';
import {
  LocationOn,
  MenuBook,
  LocationCity,
  Money,
  MoneyOutlined,
  AttachMoney,
  MapOutlined,
  LegendToggleOutlined,
  Person,
  TrackChangesOutlined,
  AdjustOutlined,
  Adjust,
  Approval,
  Flag,
  CorporateFare,
  Sailing,
  DirectionsBoat,
} from "@mui/icons-material";

const useStyles = makeStyles((theme) => ({
  menuText: {
    color: "#FFF",
  },
  menuIcon: {
    color: "#FFF",
  },
}));
const englishUI = true;
const menuTitles = englishUI
  ? {
      DanhMuc: "List",
      Graphs: "Graph",
      Ships: "Ship",
      Voyage: "Voyage",
      CheckRule: "Check rule",
      Email: "Email config",
    }
  : {
      DanhMuc: "Danh mục",
      Graphs: "Đồ thị",
      Ships: "Tàu",
      Voyage: "Chuyến đi",
      CheckRule: "Quy tắc kiểm soát",
      Email: "Cấu hình email",
    };
export default function MenuDanhMuc(props) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const hasGraph = global.hasRoles("R11");

  const hashShip = global.hasRoles("R12");
  const hasVoyage = global.hasRoles("R13");
  const hasCheckRule = global.hasRoles("R14");

  return (
    <>
      <IconButton
        id="basic-button"
        sx={{ marginLeft: "auto" }}
        size="large"
        aria-controls="basic-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        onClick={handleClick}
      >
        {open ? (
          <ArrowDropDownIcon className={classes.menuIcon} />
        ) : (
          <ArrowRightIcon className={classes.menuIcon} />
        )}
        <Typography className={classes.menuText} variant="h6" noWrap>
          {menuTitles.DanhMuc}
        </Typography>
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {hasGraph ? (
          <div>
            <MenuItem
              disabled={!hasGraph}
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "DM_Graph",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <DirectionsBoat fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.Graphs}</ListItemText>
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        {hashShip ? (
          <div>
            <MenuItem
              disabled={!hashShip}
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "DM_Ship",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <DirectionsBoat fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.Ships}</ListItemText>
            </MenuItem>
            <Divider />
          </div>
        ) : null}
        {hasVoyage ? (
          <div>
            <MenuItem
              disabled={!hasVoyage}
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "DM_Voyage",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <DirectionsBoat fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.Voyage}</ListItemText>
            </MenuItem>
            <Divider />
          </div>
        ) : null}

        {hasCheckRule ? (
          <div>
            <MenuItem
              onClick={() => {
                if (props.onClick) {
                  props.onClick({
                    name: "DM_CheckRule",
                    title: "",
                  });
                }
                handleClose();
              }}
            >
              <ListItemIcon>
                <LegendToggleOutlined fontSize="small" />
              </ListItemIcon>
              <ListItemText>{menuTitles.CheckRule}</ListItemText>
            </MenuItem>
          </div>
        ) : null}
        
      </Menu>
    </>
  );
}
